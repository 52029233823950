import { PaystackConfig } from './cronpay.prod';

export const dev = {
  '001': {
    id: '01H787F79ZTG2Q4XQHCYQ6HW0F',
    created: '2023-08-07T14:47:27.295Z',
    modified: '2023-08-07T14:47:27.295Z',
    status: 'Active',
    name: '1 child (Monthly)',
    amount: 199,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '002': {
    id: '01H787F7PHK3SVNHJ8NT33X9PY',
    created: '2023-08-07T14:47:27.697Z',
    modified: '2023-08-07T14:47:27.697Z',
    status: 'Active',
    name: '2 children (Monthly)',
    amount: 398,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '003': {
    id: '01H787F7AQRJKZ4XQKPYAMMP07',
    created: '2023-08-07T14:47:27.319Z',
    modified: '2023-08-07T14:47:27.319Z',
    status: 'Active',
    name: '3 children (Monthly)',
    amount: 597,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '004': {
    id: '01H787F7AHYHVXAJY2FZVDD9W4',
    created: '2023-08-07T14:47:27.313Z',
    modified: '2023-08-07T14:47:27.313Z',
    status: 'Active',
    name: '4 children (Monthly)',
    amount: 796,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '005': {
    id: '01H787F7P9KQE29BZA7YF57RCA',
    created: '2023-08-07T14:47:27.690Z',
    modified: '2023-08-07T14:47:27.690Z',
    status: 'Active',
    name: '5 children (Monthly)',
    amount: 995,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '006': {
    id: '01H787F7AKTQC0HDZ5PD9A00PV',
    created: '2023-08-07T14:47:27.316Z',
    modified: '2023-08-07T14:47:27.316Z',
    status: 'Active',
    name: '6 children (Monthly)',
    amount: 1194,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '010': {
    id: '01GQ0NVQKY4B4K8ECVXK4AH5A4',
    created: '2023-08-07T14:47:27.313Z',
    modified: '2023-08-07T14:47:27.313Z',
    status: 'Active',
    name: 'Adult/teen x1 (Monthly)',
    amount: 399,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '011': {
    id: '01H787F7P8A30H9PSAFZ5NRQJM',
    created: '2023-08-07T14:47:27.688Z',
    modified: '2023-08-07T14:47:27.688Z',
    status: 'Active',
    name: 'Adult/teen x1 + 1 child (Monthly)',
    amount: 598,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '012': {
    id: '01H787F7AF87EWYBWRJB48RHYV',
    created: '2023-08-07T14:47:27.311Z',
    modified: '2023-08-07T14:47:27.311Z',
    status: 'Active',
    name: 'Adult/teen x1 + 2 children (Monthly)',
    amount: 797,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '013': {
    id: '01H787F7AMFDXGWBEPFGY8GE6W',
    created: '2023-08-07T14:47:27.316Z',
    modified: '2023-08-07T14:47:27.316Z',
    status: 'Active',
    name: 'Adult/teen x1 + 3 children (Monthly)',
    amount: 996,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '014': {
    id: '01H787F7AHAGKY7QN2YSB92BJM',
    created: '2023-08-07T14:47:27.313Z',
    modified: '2023-08-07T14:47:27.313Z',
    status: 'Active',
    name: 'Adult/teen x1 + 4 children (Monthly)',
    amount: 1195,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '015': {
    id: '01H787F7PJD3Z1NQ4BJCAQT56R',
    created: '2023-08-07T14:47:27.698Z',
    modified: '2023-08-07T14:47:27.698Z',
    status: 'Active',
    name: 'Adult/teen x1 + 5 children (Monthly)',
    amount: 1394,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '016': {
    id: '01H787F7B3VXE9YDRAYX45ZTTP',
    created: '2023-08-07T14:47:27.331Z',
    modified: '2023-08-07T14:47:27.331Z',
    status: 'Active',
    name: 'Adult/teen x1 + 6 children (Monthly)',
    amount: 1593,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '020': {
    id: '01H787F7PFWCN044DBAK97QWWG',
    created: '2023-08-07T14:47:27.695Z',
    modified: '2023-08-07T14:47:27.695Z',
    status: 'Active',
    name: 'Adult/teen x2 (Monthly)',
    amount: 778,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '021': {
    id: '01H787F7B81MNPEB3RFP95XJ9K',
    created: '2023-08-07T14:47:27.336Z',
    modified: '2023-08-07T14:47:27.336Z',
    status: 'Active',
    name: 'Adult/teen x2 + 1 child (Monthly)',
    amount: 977,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '022': {
    id: '01H787F8PBR2GC6FKVDGT2QAK1',
    created: '2023-08-07T14:47:28.716Z',
    modified: '2023-08-07T14:47:28.716Z',
    status: 'Active',
    name: 'Adult/teen x2 + 2 children (Monthly)',
    amount: 1176,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '023': {
    id: '01H787F89XM8GHTNZ4KJ30X6EJ',
    created: '2023-08-07T14:47:28.317Z',
    modified: '2023-08-07T14:47:28.317Z',
    status: 'Active',
    name: 'Adult/teen x2 + 3 children (Monthly)',
    amount: 1375,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '024': {
    id: '01H787F89RBPKAFQZB6A33X8MX',
    created: '2023-08-07T14:47:28.312Z',
    modified: '2023-08-07T14:47:28.312Z',
    status: 'Active',
    name: 'Adult/teen x2 + 4 children (Monthly)',
    amount: 1574,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '025': {
    id: '01H787F8AKACGJAHPJF11BVR36',
    created: '2023-08-07T14:47:28.339Z',
    modified: '2023-08-07T14:47:28.339Z',
    status: 'Active',
    name: 'Adult/teen x2 + 5 children (Monthly)',
    amount: 1773,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '026': {
    id: '01H787F8AB0WPM986RT4PWRDA7',
    created: '2023-08-07T14:47:28.331Z',
    modified: '2023-08-07T14:47:28.331Z',
    status: 'Active',
    name: 'Adult/teen x2 + 6 children (Monthly)',
    amount: 1972,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '030': {
    id: '01H787F8NWSEQTK192MHRDAEJ3',
    created: '2023-08-07T14:47:28.700Z',
    modified: '2023-08-07T14:47:28.700Z',
    status: 'Active',
    name: 'Adult/teen x3 (Monthly)',
    amount: 1137,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '031': {
    id: '01H787F8AF4PBX3JNT43DJ7F25',
    created: '2023-08-07T14:47:28.335Z',
    modified: '2023-08-07T14:47:28.335Z',
    status: 'Active',
    name: 'Adult/teen x3 + 1 child (Monthly)',
    amount: 1336,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '032': {
    id: '01H787F8AFPWYCPC0C7SWDKEMT',
    created: '2023-08-07T14:47:28.335Z',
    modified: '2023-08-07T14:47:28.335Z',
    status: 'Active',
    name: 'Adult/teen x3 + 2 children (Monthly)',
    amount: 1535,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '033': {
    id: '01H787F8B1DH8KB9GJBKS4XZKX',
    created: '2023-08-07T14:47:28.353Z',
    modified: '2023-08-07T14:47:28.353Z',
    status: 'Active',
    name: 'Adult/teen x3 + 3 children (Monthly)',
    amount: 1734,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '034': {
    id: '01H787F8A59HPBNNNKT60FCZ3E',
    created: '2023-08-07T14:47:28.325Z',
    modified: '2023-08-07T14:47:28.325Z',
    status: 'Active',
    name: 'Adult/teen x3 + 4 children (Monthly)',
    amount: 1933,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '035': {
    id: '01H787F8AGENMB0HRXYYDCGQRB',
    created: '2023-08-07T14:47:28.336Z',
    modified: '2023-08-07T14:47:28.336Z',
    status: 'Active',
    name: 'Adult/teen x3 + 5 children (Monthly)',
    amount: 2132,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '036': {
    id: '01H787F8ANWGGWKD8Q6EVBE09T',
    created: '2023-08-07T14:47:28.341Z',
    modified: '2023-08-07T14:47:28.341Z',
    status: 'Active',
    name: 'Adult/teen x3 + 6 children (Monthly)',
    amount: 2331,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '040': {
    id: '01H787F8PMVV3CXRHKHCRQW739',
    created: '2023-08-07T14:47:28.724Z',
    modified: '2023-08-07T14:47:28.724Z',
    status: 'Active',
    name: 'Adult/teen x4 (Monthly)',
    amount: 1476,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '041': {
    id: '01H787F8PMWG6AXWQTQXCBX783',
    created: '2023-08-07T14:47:28.724Z',
    modified: '2023-08-07T14:47:28.724Z',
    status: 'Active',
    name: 'Adult/teen x4 + 1 child (Monthly)',
    amount: 1675,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '042': {
    id: '01H787F9NWVT4R5FJJ13Z1H26P',
    created: '2023-08-07T14:47:29.724Z',
    modified: '2023-08-07T14:47:29.724Z',
    status: 'Active',
    name: 'Adult/teen x4 + 2 children (Monthly)',
    amount: 1874,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '043': {
    id: '01H787F99Y4681N4XX2ME6RWFB',
    created: '2023-08-07T14:47:29.342Z',
    modified: '2023-08-07T14:47:29.342Z',
    status: 'Active',
    name: 'Adult/teen x4 + 3 children (Monthly)',
    amount: 2073,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '044': {
    id: '01H787F9MDGFADYDE529VKWBV8',
    created: '2023-08-07T14:47:29.677Z',
    modified: '2023-08-07T14:47:29.677Z',
    status: 'Active',
    name: 'Adult/teen x4 + 4 children (Monthly)',
    amount: 2272,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '045': {
    id: '01H787F8NVN293N7Y527MGN5J4',
    created: '2023-08-07T14:47:28.699Z',
    modified: '2023-08-07T14:47:28.699Z',
    status: 'Active',
    name: 'Adult/teen x4 + 5 children (Monthly)',
    amount: 2471,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '046': {
    id: '01H787F9BPVDRGDMT71HPAS6KD',
    created: '2023-08-07T14:47:29.398Z',
    modified: '2023-08-07T14:47:29.398Z',
    status: 'Active',
    name: 'Adult/teen x4 + 6 children (Monthly)',
    amount: 2670,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '050': {
    id: '01H787F99ZSZER5YW0HJ29R735',
    created: '2023-08-07T14:47:29.343Z',
    modified: '2023-08-07T14:47:29.343Z',
    status: 'Active',
    name: 'Adult/teen x5 (Monthly)',
    amount: 1795,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '051': {
    id: '01H787F9A7DEDFG2YSP1TZVDJZ',
    created: '2023-08-07T14:47:29.351Z',
    modified: '2023-08-07T14:47:29.351Z',
    status: 'Active',
    name: 'Adult/teen x5 + 1 child (Monthly)',
    amount: 1994,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '052': {
    id: '01H787F9NC1WDX1DK4J880QK4J',
    created: '2023-08-07T14:47:29.708Z',
    modified: '2023-08-07T14:47:29.708Z',
    status: 'Active',
    name: 'Adult/teen x5 + 2 children (Monthly)',
    amount: 2193,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '053': {
    id: '01H787F9NE48DNDXDB33DZ1AYG',
    created: '2023-08-07T14:47:29.710Z',
    modified: '2023-08-07T14:47:29.710Z',
    status: 'Active',
    name: 'Adult/teen x5 + 3 children (Monthly)',
    amount: 2392,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '054': {
    id: '01H787F99Y6KNXHYE2GEEG9ET7',
    created: '2023-08-07T14:47:29.342Z',
    modified: '2023-08-07T14:47:29.342Z',
    status: 'Active',
    name: 'Adult/teen x5 + 4 children (Monthly)',
    amount: 2591,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '055': {
    id: '01H787F9N6B0S1CY6MZKZSXNNX',
    created: '2023-08-07T14:47:29.703Z',
    modified: '2023-08-07T14:47:29.703Z',
    status: 'Active',
    name: 'Adult/teen x5 + 5 children (Monthly)',
    amount: 2790,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '056': {
    id: '01H787F9A0KV3G2FJJT4BHPC62',
    created: '2023-08-07T14:47:29.345Z',
    modified: '2023-08-07T14:47:29.345Z',
    status: 'Active',
    name: 'Adult/teen x5 + 6 children (Monthly)',
    amount: 2989,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '060': {
    id: '01H787F9NPGCJWPAWY8D2ECSGR',
    created: '2023-08-07T14:47:29.718Z',
    modified: '2023-08-07T14:47:29.718Z',
    status: 'Active',
    name: 'Adult/teen x6 (Monthly)',
    amount: 2094,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '061': {
    id: '01H787FA8CRVF38Q0J7CZN7Z5W',
    created: '2023-08-07T14:47:30.317Z',
    modified: '2023-08-07T14:47:30.317Z',
    status: 'Active',
    name: 'Adult/teen x6 + 1 child (Monthly)',
    amount: 2293,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '062': {
    id: '01H787FA8D1CE8KRG5TP17D53M',
    created: '2023-08-07T14:47:30.318Z',
    modified: '2023-08-07T14:47:30.318Z',
    status: 'Active',
    name: 'Adult/teen x6 + 2 children (Monthly)',
    amount: 2492,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '063': {
    id: '01H787FA8CY9DF17562SFBB8ZV',
    created: '2023-08-07T14:47:30.316Z',
    modified: '2023-08-07T14:47:30.316Z',
    status: 'Active',
    name: 'Adult/teen x6 + 3 children (Monthly)',
    amount: 2691,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '064': {
    id: '01H787F9AFR6HRWXQXKXNHME9K',
    created: '2023-08-07T14:47:29.359Z',
    modified: '2023-08-07T14:47:29.359Z',
    status: 'Active',
    name: 'Adult/teen x6 + 4 children (Monthly)',
    amount: 2890,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '065': {
    id: '01H787F9A5RJDXZ0DEEHF1BZPZ',
    created: '2023-08-07T14:47:29.349Z',
    modified: '2023-08-07T14:47:29.349Z',
    status: 'Active',
    name: 'Adult/teen x6 + 5 children (Monthly)',
    amount: 3089,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  },
  '066': {
    id: '01H787FA88VEC95Y7WSKS2TX61',
    created: '2023-08-07T14:47:30.313Z',
    modified: '2023-08-07T14:47:30.313Z',
    status: 'Active',
    name: 'Adult/teen x6 + 6 children (Monthly)',
    amount: 3288,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01H77XHZAXG2MBMVWED0F5CJHN'
  }
} as PaystackConfig;
