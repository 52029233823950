import cronParser from 'cron-parser';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const phoneRegExp = /^((\+[1-9]{2})|0)[1-8][0-9]{8}$/;
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_.])[\w\d!@#$%^&*\-_.]/;
export const nameRegExp = /^[^±!@£$%^&*_+§¡€#¢¶•ªº«\\/<>?:;|=.,0-9()]*$/;

export const sanitizePhone = (phone: string): string => phone.replace(/^0/, '+27');

export const intervalToReadable = (interval: 'daily' | 'Daily' | 'monthly' | 'Monthly') => {
  let phrase: string;

  switch (interval) {
    case 'daily':
    case 'Daily':
      phrase = 'per day';
      break;
    case 'monthly':
    case 'Monthly':
      phrase = 'per month';
      break;
    default:
      phrase = '';
  }

  return phrase;
};

export const getNextChargeDate = (cron: string, startDate?: string): dayjs.Dayjs => {
  const startDateObj = getSafeChargeDate(dayjs(startDate));
  const isPaused = startDate && dayjs().isBefore(startDateObj);

  return getSafeChargeDate(
    dayjs(
      isPaused
        ? cronParser
            .parseExpression(cron, { currentDate: startDateObj.subtract(1, 'day').toDate() })
            .next()
            .toString()
        : cronParser.parseExpression(cron).next().toString()
    )
  );
};

export const cronToReadable = (cron: string, startDate?: string): string => {
  const date = getNextChargeDate(cron, startDate);

  return `${date.format('D MMMM')} (in ${date.fromNow(true)})`;
};

export const getSafeChargeDate = (date: dayjs.Dayjs): dayjs.Dayjs => {
  const day = parseInt(date.format('D'), 10);

  // Normalise date for consistent charge day per month
  if (day > 28) {
    return dayjs(`${date.format('YYYY-MM')}-28`);
  }

  return date;
};

export const isAdmin = (email: string): boolean => {
  let matchAdminList: boolean;

  switch (email?.split('@')[1]) {
    case 'vitaminclub.co.za':
    case 'vitamin.me':
    case 'simplyanvil.com':
      matchAdminList = true;
      break;

    default:
      matchAdminList = false;
  }

  return matchAdminList;
};
