import React from 'react';
import { ChakraProvider, ColorMode, extendTheme, LightMode } from '@chakra-ui/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/main.scss';
import { ApolloClient, ApolloLink, createHttpLink, fromPromise, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { theme as proTheme } from '@chakra-ui/pro-theme';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import { LinkedInInsightTag } from 'nextjs-linkedin-insight-tag';
import { AuthProvider } from '../contexts/AuthContext';
import { ShopProvider } from '../contexts/ShopContext';
import Cart from '../components/Cart';
import { AnalyticsProvider } from '../contexts/AnalyticsContext';
import '@fontsource/lato/400.css';
import '@fontsource/dosis/500.css';
import { PirschAnalyticsProvider } from '../contexts/PirschAnalyticsContext';

// Polyfills
require('polyfill-object.fromentries');

/* AWS Amplify and Appsync */
const APPSYNC_URL = process.env.NEXT_PUBLIC_APP_SYNC_API_URL;
const httpLink = createHttpLink({
  uri: APPSYNC_URL
});
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  try {
    const session = await fetchAuthSession();
    const token = session.tokens?.idToken?.toString();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token
      }
    };
  } catch (err) {
    console.error('Auth session token error', err);
    return {
      headers: {
        ...headers
      }
    };
  }
});

// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.error('graphQLErrors', graphQLErrors);
  if (graphQLErrors) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line consistent-return
    graphQLErrors.forEach((err: { errorType: string }) => {
      // eslint-disable-next-line default-case
      switch (err?.errorType) {
        case 'UNAUTHENTICATED':
          // error code is set to UNAUTHENTICATED
          // when AuthenticationError thrown in resolver

          return fromPromise(
            fetchAuthSession()
              .then((session) => {
                console.info('Refreshed session token: ', session);

                return session.tokens?.idToken?.toString();
              })
              .catch((error) => {
                // Handle token refresh errors, e.g. clear stored tokens, redirect to sign-in, ...
                // eslint-disable-next-line no-restricted-globals
                location.href = '/sign-in/';

                // TODO: Redirect to login with current URL in queryParam
                console.error('Error after setting token: ', error);
              })
          )
            .filter((token) => {
              // console.log('In filter: ', token);
              operation.setContext({
                headers: {
                  ...operation.getContext().headers,
                  authorization: token
                }
              });

              return Boolean(token);
            })
            .flatMap(() =>
              // retry the request, returning the new observable
              forward(operation)
            );
      }
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache()
});

const config = {
  Auth: {
    Cognito: {
      region: process.env.NEXT_PUBLIC_REGION,
      userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID as string,
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID as string,
      loginWith: {
        email: true
      }
    }
  }
};
Amplify.configure(config);

/* Chakra UI */
const themeOverrides = {
  config: {
    initialColorMode: 'light' as ColorMode,
    useSystemColorMode: false
  },
  styles: {
    global: {
      'html, body': { color: 'gray.700', bgColor: 'gray.25', zIndex: 1 },
      a: { color: 'brand.500', '&:hover': 'brand.900', letterSpacing: '1px', fontWeight: '100' },
      h1: { color: 'gray.700', letterSpacing: '1px' },
      h2: { color: 'gray.700', letterSpacing: '2px' },
      h3: { color: 'gray.700', letterSpacing: '2px' },
      h4: { color: 'gray.700', letterSpacing: '1px' },
      h5: { color: 'gray.700', letterSpacing: '1px' },
      h6: { color: 'gray.700', letterSpacing: '1px' }
    }
  },
  fontWeights: {
    normal: '400',
    medium: '400',
    bold: '500'
  },
  colors: {
    brand: {
      '50': '#FFF0E6',
      '100': '#FFD4B8',
      '200': '#FEB88A',
      '300': '#feb280',
      '400': '#fe934d',
      '500': '#FE7820',
      '600': '#CB5101',
      '700': '#983D01',
      '800': '#662900',
      '900': '#331400'
    },
    orange: {
      '50': '#FFF0E6',
      '100': '#FFD4B8',
      '200': '#FEB88A',
      '300': '#feb280',
      '400': '#fe934d',
      '500': '#FE7820',
      '600': '#CB5101',
      '700': '#983D01',
      '800': '#662900',
      '900': '#331400'
    }
  },
  fonts: {
    heading: 'Dosis, Tahoma, Verdana, sans-serif',
    body: 'Lato, Tahoma, Verdana, sans-serif'
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: '100'
      }
    },
    Button: {
      variants: {
        outline: ({ colorScheme }) => {
          if (colorScheme === 'brand') {
            return {
              borderColor: 'brand.500',
              color: 'brand.500'
            };
          }

          return {};
        }
      }
    }
  }
};
const theme = extendTheme(proTheme, themeOverrides);

interface AppProps {
  Component: any;
  pageProps: any;
}

const App: React.FC<AppProps> = ({ Component, pageProps }) => (
  <ChakraProvider theme={theme} resetCSS>
    <DefaultSeo
      dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
      dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
      openGraph={{
        type: 'website',
        locale: 'en_GB',
        url: 'https://vitaminclub.co.za/',
        site_name: 'Vitamin Club',
        images: [
          {
            url: 'https://vitaminclub.co.za/images/product.jpg',
            width: 800,
            height: 800,
            alt: 'Vitamin Club'
          }
        ]
      }}
      twitter={{
        handle: '@vitaminclubza',
        site: '@vitaminclubza',
        cardType: 'summary_large_image'
      }}
    />

    <ApolloProvider client={client as any}>
      <PirschAnalyticsProvider>
        <AnalyticsProvider>
          <AuthProvider>
            <ShopProvider>
              {/* Google Analytics */}
              <Script
                strategy='lazyOnload'
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
              />
              <Script id='ga-analytics'>
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
    
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}'); // Google Analytics
                gtag('config', 'AW-16618639802'); // Google Ads
              `}
              </Script>

              {/* Facebook Pixel Code */}
              <Script id='facebook-pixel'>
                {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window,document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID || ''}');
                fbq('track', 'PageView');
              `}
              </Script>

              <noscript>
                <img
                  height='1'
                  width='1'
                  src={`https://www.facebook.com/tr?id=${
                    process.env.NEXT_PUBLIC_FB_PIXEL_ID || ''
                  }&ev=PageView &noscript=1`}
                  alt=''
                />
              </noscript>
              {/* LinkedIn */}
              <LinkedInInsightTag />
              {/* Content */}
              <LightMode>
                <Cart />
                <Component {...pageProps} />
              </LightMode>
            </ShopProvider>
          </AuthProvider>
        </AnalyticsProvider>
      </PirschAnalyticsProvider>
    </ApolloProvider>
  </ChakraProvider>
);

export default App;
