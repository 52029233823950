import React, { FC, useContext, useState } from 'react';
import { Flex, Grid, GridItem, Text, Box, Badge, Spinner } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Profile } from '../typings';
import { ShopDispatchContext, discounts, calculateItemDiscount, getPrice } from '../contexts/ShopContext';

interface CartItemProps {
  profile: Profile;
  onError: (err: string | undefined) => void;
  index: number;
}

const CartItem: FC<CartItemProps> = ({ profile, index, onError }) => {
  const [removing, setRemoving] = useState(false);
  const { removeProfile } = useContext(ShopDispatchContext);
  const discount = calculateItemDiscount(index, profile);

  const handleRemove = async () => {
    setRemoving(true);
    onError(undefined);

    try {
      await removeProfile(profile.id as string);
    } catch (err: any) {
      setRemoving(false);

      onError(`Could not remove profile. Error: ${err.message}`);
    }
  };

  return (
    <Grid templateColumns='repeat(5, 1fr)' gap={1} key={profile.id} mb='2'>
      <GridItem display='flex' alignItems='center' justifyContent='center'>
        {!removing && <CloseIcon cursor='pointer' onClick={handleRemove} />}
        {removing && <Spinner size='sm' />}
      </GridItem>

      <GridItem colSpan={2}>
        <Flex alignItems='center'>
          <Text>{profile.nickname}</Text>
        </Flex>
      </GridItem>

      <GridItem colSpan={2}>
        <Box>
          {index === 0 && <Text textAlign='right'>R {getPrice(profile)}.00</Text>}
          {index > 0 && (
            <Flex textAlign='right' justifyContent='space-between'>
              <Badge colorScheme='orange' variant='outline'>
                {discount.name} off
              </Badge>
              <Text>R {Math.floor(getPrice(profile) * (1 - discounts[index] / 100))}.00</Text>
            </Flex>
          )}
        </Box>
      </GridItem>
    </Grid>
  );
};

export default CartItem;
