import { gql } from '@apollo/client';

export const addressList = gql`
  query AddressList($qLimit: Int, $qSkip: Int) {
    listAddresses(limit: $qLimit, skip: $qSkip) {
      limit
      skip
      total
      results {
        id
        suite
        street
        city
        state
        country
        zip
        created
        modified
        status
        note
      }
    }
  }
`;

export const subscriptionsList = gql`
  query SubscriptionsList($qLimit: Int, $qSkip: Int) {
    listSubscriptions(limit: $qLimit, skip: $qSkip) {
      data {
        id
        status
        subscription_code
        email_token
        amount
        next_payment_date
        createdAt
        plan {
          name
          plan_code
          description
          amount
          interval
          currency
        }
      }
    }
  }
`;

export const UpdateSubscriptionPayment = gql`
  query updateSubscriptionCard($input: updateCardInput!) {
    updateSubscriptionCard(input: $input) {
      status
      message
      data {
        link
      }
    }
  }
`;

export const profileList = gql`
  query ProfilesList($qLimit: Int, $qSkip: Int) {
    profileList(limit: $qLimit, skip: $qSkip) {
      limit
      skip
      total
      results {
        id
        nickname
        sex
        age
        diet
        weight
        activity
        dob
        profileCombination
        created
        modified
        status
        colour
      }
    }
  }
`;

export const getProfile = gql`
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      nickname
      sex
      age
      diet
      weight
      activity
      dob
      profileCombination
      created
      modified
      status
      colour
    }
  }
`;

export const validatePromoCode = gql`
  query ValidatePromoCode($code: String!) {
    validatePromoCode(code: $code) {
      valid
      message
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getUser($input: getUserInput) {
    getUser(input: $input) {
      id
      status
      fName
      lName
      email
      phone
      created
      referralCode
      subscriptions {
        status
      }
      addresses {
        id
        suite
        street
        city
        state
        zip
        note
        country
        status
      }
      profiles {
        id
        profileCombination
        nickname
        sex
        age
        diet
        activity
        weight
        status
        colour
      }
    }
  }
`;

// Hades
export const subscriptionsListV2 = gql`
  query SubscriptionsListV2($qLimit: Int, $qSkip: Int) {
    listSubscriptionsV2(limit: $qLimit, skip: $qSkip) {
      results {
        id
        status
        created
        plan {
          name
          amount
          interval
        }
        startDate
        cron
        iterations
        address {
          id
          suite
          street
          city
          state
          country
          zip
          status
          note
        }
        profiles {
          id
          nickname
          sex
          age
          diet
          weight
          activity
          dob
          profileCombination
          status
          colour
        }
      }
    }
  }
`;
