/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import Router from 'next/router';
import Script from 'next/script';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import TiktokPixel from 'tiktok-pixel';
import { AuthStateContext } from './AuthContext';
import { PirschAnalyticsContext, PirschAnalyticsProvider } from './PirschAnalyticsContext';

export type PirschEvent = {
  name: 'Subscribed' | 'Paused' | 'Cancelled' | string;
  meta: {
    Value?: number;
    Label?: string;
    Code?: string;
    Reason?: string;
    Option?: string;
  };
};
export type AnalyticsLogEvent = UaEventOptions & Partial<PirschEvent>;
export type AnalyticsContextType = {
  logEvent(params: AnalyticsLogEvent): void;
};

type AnalyticsContextProps = {
  children: React.ReactNode;
};

export const AnalyticsContext = createContext({} as AnalyticsContextType);

export function AnalyticsProvider({ children }: AnalyticsContextProps): any {
  const { user } = useContext(AuthStateContext);
  const { logEvent: LogPirschEvent } = useContext(PirschAnalyticsContext);

  // we create a default state to keep track of whether GA
  // has been initialized, if we're tracking a unique user,
  // and to hold all of our trackers
  const [analytics, setAnalytics] = useState({
    isInitialized: false,
    hasUser: false
  });

  // We create a function handle all route changes that occur
  // and track a users movements across pages in our app
  const handleRouteChange = (url) => {
    ReactGA.send({ hitType: 'pageview', page: url });

    // @ts-ignore
    window.fbq('track', 'PageView');

    // TikTok
    TiktokPixel.pageView();
  };

  // We'll define our logEvent function before useEffect
  const logEvent = (params: AnalyticsLogEvent) => {
    if (analytics.isInitialized) {
      ReactGA.event({ ...params });

      // Send Meta Pixel events for conversion tracking
      switch (params.action) {
        case 'Add profile':
        case 'add_profile':
          // @ts-ignore
          window.fbq('track', 'AddToCart', { value: 399.0, currency: 'ZAR' }); // TODO: Calculate actual fee
          // @ts-ignore
          window.fbq('track', 'CustomizeProduct');

          // TikTok
          TiktokPixel.track('AddToCart', {
            content_type: 'product',
            value: 399,
            currency: 'ZAR'
          });

          break;

        case 'promo_code':
        case 'referrer':
          // @ts-ignore
          window.fbq('track', 'CustomizeProduct');
          break;

        case 'signed_up':
          // @ts-ignore
          window.fbq('track', 'CompleteRegistration');
          break;

        case 'Purchase':
        case 'checkout_success':
          // @ts-ignore
          window.fbq('track', 'Purchase', { value: params.value, currency: 'ZAR' });
          // @ts-ignore
          window.fbq('track', 'Subscribe', {
            value: params.value?.toString() || '0.00',
            currency: 'ZAR',
            predicted_ltv: params?.value ? (params?.value * 2).toString() : '0.00' // TODO: Update based on average
          });

          // TikTok
          TiktokPixel.track('CompletePayment', {
            content_type: 'product',
            value: params.value,
            currency: 'ZAR'
          });
          break;

        default:
          // @ts-ignore
          window.fbq('track', 'ViewContent', {
            content_category: params.category,
            content_type: params.action,
            content_name: params.label,
            value: params.value
          });
          break;
      }

      const pirschParams: PirschEvent = {
        name: params.name || (!params.action ? params.category : `${params.category}: ${params.action}`),
        meta: { ...params.meta }
      };

      if (!params.meta && params.label) {
        pirschParams.meta = { ...(pirschParams.meta || {}), Label: params.label };
      }

      if (!params.meta && params.value) {
        pirschParams.meta = { ...(pirschParams.meta || {}), Value: params.value };
      }

      try {
        LogPirschEvent(pirschParams)
          .then()
          .catch((err) => console.error(err));
      } catch (e) {
        console.error(e);
      }
    }
  };

  // We only want to initialize GA on the client side
  // This will fail if you're trying to initialize server side
  // useEffect will help us handle this case as it only runs
  // client side
  useEffect(() => {
    const { isInitialized, hasUser } = analytics;
    // initialize GA with our tracking id
    if (!isInitialized) {
      ReactGA.initialize([
        {
          trackingId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTIC || '',
          gaOptions: user?.id ? { userId: user?.id } : {}
        }
      ]);

      // Handle all route changes
      Router.events.on('routeChangeComplete', handleRouteChange);

      // TikTok
      TiktokPixel.init(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID || '');

      setAnalytics((prev) => ({
        ...prev,
        isInitialized: true,
        hasUser: Boolean(user?.id)
      }));

      // in case we don't have the user initially,
      // we handle setting a user in our tracker
    } else if (isInitialized && !hasUser && user?.id) {
      ReactGA.set({ userId: user?.id });

      setAnalytics((prev) => ({
        ...prev,
        hasUser: Boolean(user?.id)
      }));
    }

    // clean up
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [analytics, user?.id]);

  const dispatchContextValue = useMemo(() => ({ logEvent }), [logEvent]);

  return (
    <PirschAnalyticsProvider>
      <AnalyticsContext.Provider value={dispatchContextValue}>
        {children}
        {/* Cometly */}
        <Script strategy='afterInteractive' src='https://app.cometly.com/j' />
      </AnalyticsContext.Provider>
    </PirschAnalyticsProvider>
  );
}

export const useAnalytics = () => {
  const c = useContext(AnalyticsContext);
  if (!c) throw new Error('Cannot use useAnalytics when not under the AnalyticsProvider');
  return c;
};
