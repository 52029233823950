import { gql } from '@apollo/client';

export const ADD_PROFILE = gql`
  mutation AddProfile($input: ProfileInput!) {
    addProfile(input: $input) {
      id
      nickname
      sex
      age
      diet
      weight
      activity
      dob
      profileCombination
      created
      modified
      status
      colour
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: ProfileInput!) {
    updateProfile(input: $input) {
      id
      nickname
      sex
      age
      diet
      weight
      activity
      dob
      profileCombination
      created
      modified
      status
      colour
    }
  }
`;

export const REMOVE_PROFILE = gql`
  mutation RemoveProfile($input: RemoveInput!) {
    removeProfile(input: $input) {
      id
      nickname
      sex
      age
      diet
      weight
      activity
      dob
      profileCombination
      created
      modified
      status
      colour
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation AddAddress($input: AddressInput!) {
    addAddress(input: $input) {
      id
      created
      modified
      country
      state
      zip
      city
      street
      suite
      status
      note
    }
  }
`;

export const DISABLE_SUBSCRIPTION = gql`
  mutation DisableSubscription($input: DisableSubscriptionInput!) {
    disableSubscription(input: $input) {
      status
      message
    }
  }
`;

export const CHARGE_SUBSCRIPTION = gql`
  mutation ChargeSubscription($input: ChargeSubscriptionInput!) {
    chargeSubscription(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($input: AddressUpdateInput!) {
    updateAddress(input: $input) {
      id
      created
      modified
      country
      state
      zip
      city
      street
      suite
      status
      note
    }
  }
`;

export const CANCEL_SUBSCRIPTION_V2 = gql`
  mutation cancelSubscriptionV2($input: CancelSubscriptionInputV2!) {
    cancelSubscriptionV2(input: $input) {
      id
      status
    }
  }
`;

export const PAUSE_SUBSCRIPTION_V2 = gql`
  mutation pauseSubscriptionV2($input: PauseSubscriptionInputV2!) {
    pauseSubscriptionV2(input: $input) {
      id
      cron
    }
  }
`;

export const UPGRADE_SUBSCRIPTION = gql`
  mutation upgradeSubscription($input: UpgradeSubscriptionInput!) {
    upgradeSubscription(input: $input) {
      id
      cron
    }
  }
`;
export const DOWNGRADE_SUBSCRIPTION = gql`
  mutation downgradeSubscription($input: DowngradeSubscriptionInput!) {
    downgradeSubscription(input: $input) {
      id
      cron
    }
  }
`;
