export interface Plan {
  id: string;
  name: string;
  amount: number;
  interval: 'monthly';
  currency: 'ZAR';
}

export type PaystackConfig = { [index: string]: Plan };

export const prod = {
  '001': {
    id: '01GQM0QRKWTWRRNW0XWNNPQD3S',
    created: '2023-01-25T08:29:31.132Z',
    modified: '2023-01-25T08:29:31.132Z',
    status: 'Active',
    name: '1 child (Monthly)',
    amount: 199,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '002': {
    id: '01GQM0QRJGTEEKZRZRHNNF3YGP',
    created: '2023-01-25T08:29:31.089Z',
    modified: '2023-01-25T08:29:31.089Z',
    status: 'Active',
    name: '2 children (Monthly)',
    amount: 398,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '003': {
    id: '01GQM0QQ9QW1BHWD2GNY6P3355',
    created: '2023-01-25T08:29:29.783Z',
    modified: '2023-01-25T08:29:29.783Z',
    status: 'Active',
    name: '3 children (Monthly)',
    amount: 597,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '004': {
    id: '01GQM0QRPCC6703X10A930T9E6',
    created: '2023-01-25T08:29:31.212Z',
    modified: '2023-01-25T08:29:31.212Z',
    status: 'Active',
    name: '4 children (Monthly)',
    amount: 796,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '005': {
    id: '01GQM0QQPSC8RM57F89HXSR9DF',
    created: '2023-01-25T08:29:30.201Z',
    modified: '2023-01-25T08:29:30.201Z',
    status: 'Active',
    name: '5 children (Monthly)',
    amount: 995,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '006': {
    id: '01GQM0QRHWJ9SV1ZZMADHJV5R5',
    created: '2023-01-25T08:29:31.068Z',
    modified: '2023-01-25T08:29:31.068Z',
    status: 'Active',
    name: '6 children (Monthly)',
    amount: 1194,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '010': {
    id: '01GQM0QRT0VDDSXDKNFZZ071BW',
    created: '2023-01-25T08:29:31.328Z',
    modified: '2023-01-25T08:29:31.328Z',
    status: 'Active',
    name: 'Adult/teen x1 (Monthly)',
    amount: 399,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '011': {
    id: '01GQM0QRK745WQ8RY3KCK25TG3',
    created: '2023-01-25T08:29:31.111Z',
    modified: '2023-01-25T08:29:31.111Z',
    status: 'Active',
    name: 'Adult/teen x1 + 1 child (Monthly)',
    amount: 598,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '012': {
    id: '01GQM0QQT23N9XZW70M56X52VJ',
    created: '2023-01-25T08:29:30.306Z',
    modified: '2023-01-25T08:29:30.306Z',
    status: 'Active',
    name: 'Adult/teen x1 + 2 children (Monthly)',
    amount: 797,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '013': {
    id: '01GQM0QPZPXCW1G49F8FWPQ1RN',
    created: '2023-01-25T08:29:29.462Z',
    modified: '2023-01-25T08:29:29.462Z',
    status: 'Active',
    name: 'Adult/teen x1 + 3 children (Monthly)',
    amount: 996,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '014': {
    id: '01GQM0QRMNGVB9Q9TD3VSXV4G8',
    created: '2023-01-25T08:29:31.158Z',
    modified: '2023-01-25T08:29:31.158Z',
    status: 'Active',
    name: 'Adult/teen x1 + 4 children (Monthly)',
    amount: 1195,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '015': {
    id: '01GQM0QQWKTR4GYMHTNPPWHZT4',
    created: '2023-01-25T08:29:30.387Z',
    modified: '2023-01-25T08:29:30.387Z',
    status: 'Active',
    name: 'Adult/teen x1 + 5 children (Monthly)',
    amount: 1394,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '016': {
    id: '01GQM0QR5AKM3MKCD3X9YX9WQ1',
    created: '2023-01-25T08:29:30.666Z',
    modified: '2023-01-25T08:29:30.666Z',
    status: 'Active',
    name: 'Adult/teen x1 + 6 children (Monthly)',
    amount: 1593,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '020': {
    id: '01GQM0QS2Q8QPN7RMRY2DC5D0K',
    created: '2023-01-25T08:29:31.607Z',
    modified: '2023-01-25T08:29:31.607Z',
    status: 'Active',
    name: 'Adult/teen x2 (Monthly)',
    amount: 778,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '021': {
    id: '01GQM0QR42MA00A84ATKREQHWD',
    created: '2023-01-25T08:29:30.626Z',
    modified: '2023-01-25T08:29:30.626Z',
    status: 'Active',
    name: 'Adult/teen x2 + 1 child (Monthly)',
    amount: 977,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '022': {
    id: '01GQM0QQEQ1EMS7DXY2GXWT52T',
    created: '2023-01-25T08:29:29.943Z',
    modified: '2023-01-25T08:29:29.943Z',
    status: 'Active',
    name: 'Adult/teen x2 + 2 children (Monthly)',
    amount: 1176,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '023': {
    id: '01GQM0QRRNKS7792S6GCSVSK0D',
    created: '2023-01-25T08:29:31.285Z',
    modified: '2023-01-25T08:29:31.285Z',
    status: 'Active',
    name: 'Adult/teen x2 + 3 children (Monthly)',
    amount: 1375,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '024': {
    id: '01GQM0QQMKSNSYP9NBJJY7T076',
    created: '2023-01-25T08:29:30.131Z',
    modified: '2023-01-25T08:29:30.131Z',
    status: 'Active',
    name: 'Adult/teen x2 + 4 children (Monthly)',
    amount: 1574,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '025': {
    id: '01GQM0QRWSGDG3DRQVBAR241XW',
    created: '2023-01-25T08:29:31.417Z',
    modified: '2023-01-25T08:29:31.417Z',
    status: 'Active',
    name: 'Adult/teen x2 + 5 children (Monthly)',
    amount: 1773,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '026': {
    id: '01GQM0QSCDWD6JSNC5XYAHPB28',
    created: '2023-01-25T08:29:31.917Z',
    modified: '2023-01-25T08:29:31.917Z',
    status: 'Active',
    name: 'Adult/teen x2 + 6 children (Monthly)',
    amount: 1972,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '030': {
    id: '01GQM0QQBJ8CA2THR3VCD4YHWC',
    created: '2023-01-25T08:29:29.842Z',
    modified: '2023-01-25T08:29:29.842Z',
    status: 'Active',
    name: 'Adult/teen x3 (Monthly)',
    amount: 1137,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '031': {
    id: '01GQM0QS4AH7PGTKR494KD0XZA',
    created: '2023-01-25T08:29:31.659Z',
    modified: '2023-01-25T08:29:31.659Z',
    status: 'Active',
    name: 'Adult/teen x3 + 1 child (Monthly)',
    amount: 1336,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '032': {
    id: '01GQM0QSBZ3J16CAAB1CVH4BP1',
    created: '2023-01-25T08:29:31.903Z',
    modified: '2023-01-25T08:29:31.903Z',
    status: 'Active',
    name: 'Adult/teen x3 + 2 children (Monthly)',
    amount: 1535,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '033': {
    id: '01GQM0QS0ND8NW9W1S28J7D5FP',
    created: '2023-01-25T08:29:31.541Z',
    modified: '2023-01-25T08:29:31.541Z',
    status: 'Active',
    name: 'Adult/teen x3 + 3 children (Monthly)',
    amount: 1734,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '034': {
    id: '01GQM0QSB78K6KNXRPCVYHC7E6',
    created: '2023-01-25T08:29:31.879Z',
    modified: '2023-01-25T08:29:31.880Z',
    status: 'Active',
    name: 'Adult/teen x3 + 4 children (Monthly)',
    amount: 1933,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '035': {
    id: '01GQM0QQRS9FHNQRNPMCGR2H9N',
    created: '2023-01-25T08:29:30.265Z',
    modified: '2023-01-25T08:29:30.265Z',
    status: 'Active',
    name: 'Adult/teen x3 + 5 children (Monthly)',
    amount: 2132,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '036': {
    id: '01GQM0QQVSABZFXDS9HTM708KX',
    created: '2023-01-25T08:29:30.361Z',
    modified: '2023-01-25T08:29:30.361Z',
    status: 'Active',
    name: 'Adult/teen x3 + 6 children (Monthly)',
    amount: 2331,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '040': {
    id: '01GQM0QSACR3NATQPXGEDJ9XD0',
    created: '2023-01-25T08:29:31.853Z',
    modified: '2023-01-25T08:29:31.853Z',
    status: 'Active',
    name: 'Adult/teen x4 (Monthly)',
    amount: 1476,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '041': {
    id: '01GQM0QS8A4A6NCAMJ0SQPJA6G',
    created: '2023-01-25T08:29:31.786Z',
    modified: '2023-01-25T08:29:31.786Z',
    status: 'Active',
    name: 'Adult/teen x4 + 1 child (Monthly)',
    amount: 1675,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '042': {
    id: '01GQM0QS6278X3WDG0RD06BP3X',
    created: '2023-01-25T08:29:31.751Z',
    modified: '2023-01-25T08:29:31.751Z',
    status: 'Active',
    name: 'Adult/teen x4 + 2 children (Monthly)',
    amount: 1874,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '043': {
    id: '01GQM0QS3PQQTJC96EPSY9WVM8',
    created: '2023-01-25T08:29:31.639Z',
    modified: '2023-01-25T08:29:31.639Z',
    status: 'Active',
    name: 'Adult/teen x4 + 3 children (Monthly)',
    amount: 2073,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '044': {
    id: '01GQM0QSBXC24NWD6XXQRV7ZMM',
    created: '2023-01-25T08:29:31.901Z',
    modified: '2023-01-25T08:29:31.901Z',
    status: 'Active',
    name: 'Adult/teen x4 + 4 children (Monthly)',
    amount: 2272,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '045': {
    id: '01GQM0QS8Q9KVBJ95HEENMM1B6',
    created: '2023-01-25T08:29:31.800Z',
    modified: '2023-01-25T08:29:31.800Z',
    status: 'Active',
    name: 'Adult/teen x4 + 5 children (Monthly)',
    amount: 2471,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '046': {
    id: '01GQM0QRSQ4X386WVFNESQ20Q9',
    created: '2023-01-25T08:29:31.320Z',
    modified: '2023-01-25T08:29:31.320Z',
    status: 'Active',
    name: 'Adult/teen x4 + 6 children (Monthly)',
    amount: 2670,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '050': {
    id: '01GQM0QRMQCBAJS9BMP8F2VH0R',
    created: '2023-01-25T08:29:31.176Z',
    modified: '2023-01-25T08:29:31.176Z',
    status: 'Active',
    name: 'Adult/teen x5 (Monthly)',
    amount: 1795,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '051': {
    id: '01GQM0QS2R57F0EKEHJA7FV47Z',
    created: '2023-01-25T08:29:31.609Z',
    modified: '2023-01-25T08:29:31.609Z',
    status: 'Active',
    name: 'Adult/teen x5 + 1 child (Monthly)',
    amount: 1994,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '052': {
    id: '01GQM0QQYAKMB6VH0SR8Q0P6NT',
    created: '2023-01-25T08:29:30.442Z',
    modified: '2023-01-25T08:29:30.442Z',
    status: 'Active',
    name: 'Adult/teen x5 + 2 children (Monthly)',
    amount: 2193,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '053': {
    id: '01GQM0QQZPEFXR0TAJT2AKAX0T',
    created: '2023-01-25T08:29:30.486Z',
    modified: '2023-01-25T08:29:30.486Z',
    status: 'Active',
    name: 'Adult/teen x5 + 3 children (Monthly)',
    amount: 2392,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '054': {
    id: '01GQM0QRQ3KGC4PP7ZNS232G8Y',
    created: '2023-01-25T08:29:31.235Z',
    modified: '2023-01-25T08:29:31.235Z',
    status: 'Active',
    name: 'Adult/teen x5 + 4 children (Monthly)',
    amount: 2591,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '055': {
    id: '01GQM0QR26V9BNJ2E2J1F9Y177',
    created: '2023-01-25T08:29:30.566Z',
    modified: '2023-01-25T08:29:30.566Z',
    status: 'Active',
    name: 'Adult/teen x5 + 5 children (Monthly)',
    amount: 2790,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '056': {
    id: '01GQM0QSF34DK34P71V79X8B3H',
    created: '2023-01-25T08:29:32.003Z',
    modified: '2023-01-25T08:29:32.003Z',
    status: 'Active',
    name: 'Adult/teen x5 + 6 children (Monthly)',
    amount: 2989,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '060': {
    id: '01GQM0QQGQX3D83ZEDDNK45AKP',
    created: '2023-01-25T08:29:30.007Z',
    modified: '2023-01-25T08:29:30.007Z',
    status: 'Active',
    name: 'Adult/teen x6 (Monthly)',
    amount: 2094,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '061': {
    id: '01GQM0QRRE725X0DQAS3W4AERN',
    created: '2023-01-25T08:29:31.278Z',
    modified: '2023-01-25T08:29:31.278Z',
    status: 'Active',
    name: 'Adult/teen x6 + 1 child (Monthly)',
    amount: 2293,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '062': {
    id: '01GQM0QR0YX67ZGE3DBG2YPDCR',
    created: '2023-01-25T08:29:30.526Z',
    modified: '2023-01-25T08:29:30.526Z',
    status: 'Active',
    name: 'Adult/teen x6 + 2 children (Monthly)',
    amount: 2492,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '063': {
    id: '01GQM0QRN6Z5YSFY0NYYSW5116',
    created: '2023-01-25T08:29:31.175Z',
    modified: '2023-01-25T08:29:31.175Z',
    status: 'Active',
    name: 'Adult/teen x6 + 3 children (Monthly)',
    amount: 2691,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '064': {
    id: '01GQM0QRRY45JPNWVC4ZR2R2HV',
    created: '2023-01-25T08:29:31.294Z',
    modified: '2023-01-25T08:29:31.294Z',
    status: 'Active',
    name: 'Adult/teen x6 + 4 children (Monthly)',
    amount: 2890,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '065': {
    id: '01GQM0QR72B07279EW6AH746DJ',
    created: '2023-01-25T08:29:30.722Z',
    modified: '2023-01-25T08:29:30.722Z',
    status: 'Active',
    name: 'Adult/teen x6 + 5 children (Monthly)',
    amount: 3089,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  },
  '066': {
    id: '01GQM0QQDDWT1EA1SMRSG33SV1',
    created: '2023-01-25T08:29:29.901Z',
    modified: '2023-01-25T08:29:29.901Z',
    status: 'Active',
    name: 'Adult/teen x6 + 6 children (Monthly)',
    amount: 3288,
    interval: 'monthly',
    currency: 'ZAR',
    orgId: '01GPZXYE30X8NW3WAEDKY513AP'
  }
} as PaystackConfig;
