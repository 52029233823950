import { PaystackProps } from 'react-paystack/dist/types';

export interface Profile {
  id?: string;
  nickname?: string;
  sex?: 'male' | 'female';
  age?: 'child' | 'teen' | '20-40' | '40-55' | '55+';
  diet?: 'poor' | 'average' | 'great';
  weight?: 'underweight' | 'good' | 'overweight' | 'obese';
  activity?: 'sedentary' | 'mobile' | 'exercise' | 'athlete';
  /** @deprecated use profileCombination */
  profile?: number;
  profileCombination?: number;
  colour?: string;
  error?: string;
}

export interface StringTMap<T> {
  [key: string]: T;
}

export interface NumberTMap<T> {
  [key: number]: T;
}

export type StringStringMap = StringTMap<string>;
export type NumberStringMap = NumberTMap<string>;

/* Allows object with children of type T. eg.
 {
   a: T,
   b: {
     ba: T
   }
 } */
export type StringTMapDepth2<T> = StringTMap<T | StringTMap<T>>;
/* Allows object with grandchildren of type T. eg.
{
  a: T,
  b: {
    ba: T
  },
  c: {
    ca: T,
    cb: {
      cba: T
    }
  }
} */

// eslint-disable-next-line no-shadow
export enum Status {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REMOVED = 'Removed',
  SUBSCRIBED = 'Subscribed', // User status
  SUBSCRIPTION_DISABLED = 'Subscription disabled' // User status
}

// eslint-disable-next-line no-shadow
export enum Provinces {
  EASTERN_CAPE = 'Eastern Cape',
  FREE_STATE = 'Free State',
  GAUTENG = 'Gauteng',
  KWAZULU_NATAL = 'KwaZulu-Natal',
  LIMPOPO = 'Limpopo',
  MPUMALANGA = 'Mpumalanga',
  NORTHERN_CAPE = 'Northern Cape',
  NORTH_WEST = 'North West',
  WESTERN_CAPE = 'Western Cape'
}

export interface Address {
  id?: string;
  status?: Status;
  country: 'South Africa' | string;
  state: Provinces; // State or region
  city: string;
  street: string; // Street address
  suite: string; // Apartment or house
  zip: string;
  note?: string;
  geo?: {
    lat: string;
    lng: string;
  };
}

interface PaystackCustomFields {
  // eslint-disable-next-line camelcase
  display_name: string;
  // eslint-disable-next-line camelcase
  variable_name: string;
  value: any;
}

export interface PaystackCustomProps extends PaystackProps {
  metadata: {
    recurring?: boolean;
    addressId?: string;
    userId: string;
    profileIds?: string[];
    promoCode?: string;
    targetPlan?: string;
    // eslint-disable-next-line camelcase
    custom_fields: PaystackCustomFields[];

    // Hades
    createSubscription?: boolean;
    updateCard?: boolean;
    planId?: string;
    subscriptionId?: string;
    referrer?: string;
    referralCode?: string;
    referralId?: string;
  };
}

export interface Order {
  id: string;
  status: Status;
}

export interface User {
  id: string; // Cognito user sub
  ref: string; // ULID
  status: Status;
}

export const ageOptions: StringStringMap = {
  child: 'Child',
  teen: 'Teen',
  '20-40': '20-40',
  '40-55': '40-55',
  '55+': '55+'
};
export const dietOptions: StringStringMap = {
  poor: "Don't eat greens",
  average: 'Doing okay',
  great: 'Love greens'
};
export const weightOptions: StringStringMap = {
  underweight: 'Too skinny',
  good: 'Happy with my weight',
  overweight: 'Slightly over',
  obese: 'Overweight for now'
};
export const activityOptions: StringStringMap = {
  sedentary: 'Sit all day',
  mobile: 'Somewhat active',
  exercise: 'Exercise regularly',
  athlete: 'Uber athlete'
};
export const adultOptions: StringStringMap = {
  teen: 'Teen',
  '20-40': '20-40',
  '40-55': '40-55',
  '55+': '55+'
};
export const childOptions: StringStringMap = {
  child: 'Child'
};

export interface ReactRevealFadeProps {
  top?: boolean;
  left?: boolean;
  right?: boolean;
  bottom?: boolean;
  delay?: number;
  duration?: number;
  className?: string;
}

export type SubscriptionV2Type = {
  id: string;
  status: Status;
  created: string;
  plan: {
    name: string;
    amount: number;
    interval: 'daily' | 'monthly';
  };
  startDate: string;
  cron: string;
  iterations: number;
  address: Address;
  profiles: Profile[];
};
