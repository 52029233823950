/* eslint-disable no-param-reassign */

export interface GraphqlUtilsInterface {
  prepGQLObject: (obj: any) => any;
}
export function GraphqlUtils(): any {
  const prepGQLObject = (obj: any) => {
    if (typeof obj === 'object' && obj) {
      if (obj.length) {
        const t: any[] = obj.map((m) => prepGQLObject(m));
        return t;
      }

      const t: any = Object.keys(obj)
        .filter((f) => f !== '__typename')
        .reduce((memo, cur) => {
          memo[cur] = prepGQLObject(obj[cur]);
          return memo;
        }, {});

      return t;
    }

    return obj;
  };

  return Object.freeze({
    prepGQLObject
  });
}
