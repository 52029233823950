import React, { FunctionComponent, useContext, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { getChildProfiles, getNonChildProfiles, ShopDispatchContext, ShopStateContext } from '../contexts/ShopContext';
import { AuthStateContext } from '../contexts/AuthContext';
import CartItem from './CartItem';

// TODO: Save confirmation Url for customer's order history.

const Cart: FunctionComponent = () => {
  const [error, setError] = useState<string | undefined>();
  const { isAuthenticated } = useContext(AuthStateContext);
  const { isCartOpen, nextDiscount, profiles } = useContext(ShopStateContext);
  const { dispatch } = useContext(ShopDispatchContext);
  const router = useRouter();

  const toggleCart = () => {
    dispatch({ type: 'toggleCartOpen' });
  };

  return (
    <>
      <Drawer isOpen={isCartOpen} placement='right' onClose={toggleCart} size='sm'>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton mt={{ base: '85px', md: 0 }} />
            <DrawerHeader mt={{ base: 20, md: 0 }}>Currently in your cart</DrawerHeader>
            <DrawerBody>
              {error && (
                <Alert status='error' borderRadius='md'>
                  <AlertIcon />
                  <Box>
                    <AlertTitle fontWeight='bolder'>Unable to remove profile at this time</AlertTitle>
                    <AlertDescription>Please check your connection and try again.</AlertDescription>
                  </Box>
                </Alert>
              )}

              {profiles.length ? (
                <>
                  {/* Adult and teen profiles */}
                  {getNonChildProfiles(profiles).length > 0 && (
                    <Box mb={6} mt={2}>
                      <Text fontWeight='semibold' mb={4}>
                        Adult and teens profiles
                      </Text>
                      {Object.values(getNonChildProfiles(profiles)).map((profile: any, index: number) => (
                        <CartItem profile={profile} index={index} key={profile.id} onError={setError} />
                      ))}
                    </Box>
                  )}

                  {/* Child profiles */}
                  {getChildProfiles(profiles).length > 0 && (
                    <Box mb={6}>
                      <Text fontWeight='semibold' mb={4}>
                        Child profiles
                      </Text>
                      {Object.values(getChildProfiles(profiles)).map((profile: any) => (
                        <CartItem profile={profile} index={0} key={profile.id} onError={setError} />
                      ))}
                    </Box>
                  )}

                  <Box w='100%' h='100%'>
                    <Text as='span' h='50%' display='flex' flexDir='column' alignItems='center' justifyContent='center'>
                      <Button as={NextLink} href='/quiz/' onClick={toggleCart} size='lg'>
                        Add a family member
                      </Button>
                      {nextDiscount > 0 && <Text mt='2'>Get {nextDiscount}% off the next adult or teen profile</Text>}
                    </Text>
                  </Box>
                </>
              ) : (
                <Box w='100%' h='100%'>
                  <Text h='100%' display='flex' flexDir='column' alignItems='center' justifyContent='center'>
                    You currently have no profiles added.
                  </Text>
                </Box>
              )}
            </DrawerBody>
            <DrawerFooter>
              {isAuthenticated && profiles.length > 0 && (
                <Button
                  as={NextLink}
                  href='/checkout/'
                  w='100%'
                  size='lg'
                  colorScheme='orange'
                  color='white'
                  cursor='pointer'
                  backgroundColor='orange.500'
                  fontWeight='extrabold'
                  _hover={{
                    background: 'orange.600',
                    color: 'white'
                  }}
                  onClick={toggleCart}
                >
                  Continue to checkout
                </Button>
              )}
              {!isAuthenticated && profiles.length > 0 && (
                <Button
                  w='100%'
                  size='lg'
                  as='a'
                  colorScheme='orange'
                  color='white'
                  cursor='pointer'
                  backgroundColor='orange.500'
                  fontWeight='extrabold'
                  _hover={{
                    background: 'orange.600',
                    color: 'white'
                  }}
                  onClick={() => {
                    toggleCart();
                    router.push('/sign-up/');
                  }}
                >
                  Continue to checkout
                </Button>
              )}
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
export default Cart;
