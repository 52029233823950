import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Pirsch, PirschWebClient } from 'pirsch-sdk/web';
import { PirschBrowserHit, Scalar } from 'pirsch-sdk';
import Script from 'next/script';

export type PirschEvent = {
  name: string;
  duration?: number;
  meta?: Record<string, Scalar>;
  hit?: Partial<PirschBrowserHit>;
};

export type PirschAnalyticsContextType = {
  logEvent(params: PirschEvent): Promise<void>;
};

type AnalyticsContextProps = {
  children: React.ReactNode;
};

export const PirschAnalyticsContext = createContext({} as PirschAnalyticsContextType);

export function PirschAnalyticsProvider({ children }: AnalyticsContextProps): any {
  const [client, setClient] = useState<PirschWebClient>();

  const logEvent = async ({ name, meta, duration, hit }: PirschEvent) => {
    if (process.env.NEXT_PUBLIC_STAGE === 'prod' && client) {
      await client
        .event(name, duration, meta, hit)
        .catch((err) => console.error('Could not send Pirsch data:', err.message));
    }

    if (process.env.NEXT_PUBLIC_STAGE !== 'prod') {
      console.info('Pirsch event', { name, duration, meta, hit });
    }
  };

  // We only want to initialize GA on the client side
  // This will fail if you're trying to initialize server side
  // useEffect will help us handle this case as it only runs
  // client side
  useEffect(() => {
    // Initialize tracking code for non-local only
    if (process.env.NEXT_PUBLIC_STAGE === 'prod' && !client) {
      // Create a client with the identification code you have configured on the Pirsch dashboard.
      const newClient = new Pirsch({
        identificationCode: process.env.NEXT_PUBLIC_PIRSCH_CODE || ''
      });

      setClient(newClient);
    }
  }, []);

  const dispatchContextValue = useMemo(() => ({ logEvent }), [logEvent]);

  return (
    <PirschAnalyticsContext.Provider value={dispatchContextValue}>
      {children}
      <Script
        defer
        type='text/javascript'
        src='https://api.pirsch.io/pirsch.js'
        id='pirschjs'
        data-code='jR5ASgjduIEGKK50qXA9ZWW9C3MHK2UC'
      />
    </PirschAnalyticsContext.Provider>
  );
}

export const usePirschAnalytics = () => {
  const c = useContext(PirschAnalyticsContext);
  if (!c) throw new Error('Cannot use usePirschAnalytics when not under the PirschAnalyticsProvider');
  return c;
};
